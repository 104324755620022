 .main_slider{
    background-color: rgb(236, 231, 222);
    padding: 20px 40px;
 }
 
 
 .slider{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 10px;
    width: 100%;
 }

 .slider img {
  width: 303px;
  height: 303px;
  object-fit: cover;
}

 .slider .each_img {
      cursor : pointer;
      display : flex;
      flex-direction: column;
      justify-content: space-between; 
  }

  .slider .img_description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px 0px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .slider p,span {
      font-weight: normal;
      display: flex;
      flex-wrap: wrap;
      color: black;
      font-size: 25px;
      width: 405px;
      margin-top: 5px;
    }

  .slider  span {
      font-weight: bold;
      color: black;
      font-size: 20px;
      padding: 5px;
    }

 .slider .slick-dots li.slick-active button:before {
    color: #881798;
  }
  
 .slider .slick-prev:before,
  .slick-next:before {
    /* color: #881798; */
    color: black;
  }
  